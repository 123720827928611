<template>
    <modal @onSave="save" @onClose="close" :show="showModal">
        <template #title>
            Leverancier koppelen
        </template>
        <order-item-teaser v-if="orderItem" :with-customer="true" :order-item="orderItem"></order-item-teaser>
        <div class="row" v-if="!loading">
            <div class="col-12">
                <table class="table">
                    <thead>
                    <tr>
                        <th><input type="checkbox" v-model="checkAllBox" @click="checkAll()" class="mr-1"
                                   style="vertical-align: sub;"> Versturen?
                        </th>
                        <th></th>
                        <th>#</th>
                        <th>Leverancier</th>
                        <th></th>
                        <th>Prijs</th>
                        <th>Status aanvraag</th>
                        <th>&nbsp;</th>
                    </tr>
                    </thead>
                    <draggable :move="checkMove" v-model="availableRequests" filter=".ignore" group="supplier"
                               tag="tbody">
                        <template v-for="(supplierRequest, i) in availableRequests">
                            <tr :class="supplierRequest.status_id !== 'not_sent' && supplierRequest.id ? 'ignore' : ''"
                                :key="supplierRequest.location.id">
                                <td>
                                    <input type="checkbox" v-model="supplierRequest.should_send">
                                    <p v-if="supplierRequest.distance">{{supplierRequest.distance}} km</p>
                                </td>
                                <td>
                                    <supplier-request-warning
                                        :supplier-request="supplierRequest"></supplier-request-warning>
                                </td>
                                <td v-text="i +1"></td>
                                <td>
                                    <location-name :location="supplierRequest.location" :supplier="supplierRequest.supplier"></location-name>
                                </td>
                                <td>
                                    <span v-if="supplierRequest.supplier.rating"
                                          data-toggle="popover"
                                          title="Waardering"
                                          :data-content="supplierRequest.supplier.rating_extended"
                                          data-trigger="hover"
                                          data-html="true"
                                    >
                                        <i class="fal fa-star"></i> {{ supplierRequest.supplier.rating }}
                                    </span>
                                </td>
                                <td v-if="orderItem.product && !orderItem.product.purchase_rent_only" :class="{ 'text-danger' : parseFloat(orderItem.total_price_excl) < parseFloat(supplierRequest.calculated_price_excl)}">
                                    <span class="noWrap">{{ supplierRequest.calculated_price_excl | format_money }}</span><br>
                                    <span class="noWrap">{{ supplierRequest.calculated_price_incl | format_money }}</span>
                                </td>
                                <rent-price-column v-if="orderItem.product && orderItem.product.purchase_rent_only"
                                                   :supplier-request="supplierRequest"/>
                                <td>
                                    <span v-if="supplierRequest.status_id === 'sent'" class="tag tag--warning">Aangevraagd #{{
                                            supplierRequest.supplier_order_nr
                                        }}</span>
                                    <span
                                        v-if="supplierRequest.status_id === 'accepted' && supplierRequest.conditional === false"
                                        class="tag tag--success">Geaccepteerd</span>
                                    <span
                                        v-if="supplierRequest.status_id === 'accepted' && supplierRequest.conditional === true"
                                        class="tag tag--warning">Andere levering mogelijk<p class="small">({{
                                            supplierRequest.delivery_at_formatted
                                        }})</p></span>
                                    <span v-if="supplierRequest.status_id === 'denied'" class="tag tag--danger">Leveren niet mogelijk</span>
                                    <span v-if="supplierRequest.status_id === 'not_sent'" class="tag tag--light">Beschikbaarheid onbekend</span>
                                    <span v-if="supplierRequest.status_id === 'unlinked'" class="tag tag--warning">Ontkoppeld #{{
                                            supplierRequest.supplier_order_nr
                                        }} </span>
                                    <span v-if="!supplierRequest.status_formatted" class="tag tag--light">Volgorde nog niet opgeslagen</span>
                                    <i class="ml-2 fa fa-info-circle text-success" data-toggle="data-tooltip" title="Geaccepteerd in beschikbaarheidsaanvraag" v-if="supplierRequest.supplier_availability_request"></i>
                                </td>

                                <td>
                                	<span v-if="order.id">
	                                    <a @click="toggleLinkId(supplierRequest.location.id)" href="#"
                                           :class="'btn ' +(linkLocationId == supplierRequest.location.id ? 'btn-primary' : 'btn-light')">
                                            <i class="far fa-link"></i></a>
	                                </span>
                                    <outbound-call-button :number="supplierRequest.supplier.order_intake_phone">
                                        <span class="btn btn-light"
                                              v-if="supplierRequest.supplier && supplierRequest.supplier.order_intake_phone"
                                              :title="supplierRequest.supplier.order_intake_phone"
                                              :data-title="supplierRequest.supplier.order_intake_phone"
                                              data-toggle="data-tooltip"><i class="far fa-phone"></i></span>
                                    </outbound-call-button>

                                </td>
                            </tr>
                        </template>

                    </draggable>
                </table>
                <manual-supplier-form v-if="orderItem.product && !orderItem.product.purchase_rent_only"/>
                <manual-rent-supplier-form v-if="orderItem.product && orderItem.product.purchase_rent_only"/>

            </div>


            <div class="alert alert--warning alert--large" v-if="linkLocationId">
                <strong>Koppelen met leverancier</strong>
                <ul>
                    <template v-if="order.is_swap_order && linkSupplierRequest">
                        <li>
                            Er gaat een wissel plaatsvinden
                        </li>
                        <template v-if="linkSupplierRequest.is_supplier_swap">
                            <li>
                                {{ selectedLinkLocation.name }} gaat wisselen
                            </li>
                        </template>
                        <template v-else>
                            <li>
                                {{ selectedLinkLocation.name }} deze container leveren
                            </li>
                            <li>
                                {{ order.parent.location.name }} gaat de container ophalen
                            </li>
                        </template>
                    </template>
                    <li v-else>{{ selectedLinkLocation.name }} gaat deze order leveren</li>
                </ul>
                <div class="summary">
                    <div class="summary-item">
                        <div class="tag tag--withImage tag--square" v-if="selectedLinkLocation.supplier.image_url">
                            <div class="tag-image"
                                 :style="'background-image: url(' + selectedLinkLocation.supplier.image_url + ')'"></div>
                        </div>
                        <div class="summary-item-title">
                            {{ selectedLinkLocation.name }}
                            <div class="summary-item-subtitle">
                                <span>
                                    {{ linkSupplierRequest.delivery_at_formatted }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <address-teaser :date="linkSupplierRequest.delivery_at_formatted"
                                    :address="order.delivery_address"/>
                    <short-product-teaser :order-item="orderItem"/>
                </div>
            </div>

        </div>
        <div v-else class="col-4 d-flex justify-content-center align-items-center">
            <div><i class="fa text-info fa-spinner fa-spin fa-2x"></i></div>
        </div>
        <template #footer>
            <div class="w-100">
                <div v-if="!linkLocationId" class="d-flex">
                    <div class="w-25 px-1">
                        <a :href="'/search-suppliers?date=' + order.preferred_delivery_at + '&zip_code=' + order.delivery_address.zip_code + '&category=' + orderItem.product.category_id + '&attribute=' + attribute.product_attribute.product_category_attribute_id + '&option=' + attribute.option.product_category_attribute_option_id" target="_blank" class="btn btn-light btn-block">
                            <i class="far fa-search-location fa-fw"></i>
                            Leveranciervinder
                        </a>
                    </div>
                    <div class="w-25 px-1">
                        <button type="button" @click="save(false)" class="btn btn-primary btn-block">Opslaan</button>
                    </div>
                    <div class="w-25 px-1">
                        <button v-if="order.id" type="button" @click="save(true)" class="btn btn-primary btn-block">Opslaan
                            en verzenden
                        </button>
                    </div>
                    <div class="w-25 px-1">
                        <button type="button" @click="$emit('close')" class="btn btn-secondary btn-block">Sluiten</button>
                    </div>
                </div>
                <div v-else>
                    <button type="button" @click="saveAndLink(linkLocationId)" class="btn btn-primary">Leverancier
                        koppelen
                    </button>
                    <button type="button" @click="$emit('close')" class="btn btn-secondary">Sluiten</button>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import draggable from 'vuedraggable'
import Modal from "../Modal";
import OrderItemTeaser from "../OrderItemTeaser";
import ShortProductTeaser from "../ShortProductTeaser";
import AddressTeaser from "../AddressTeaser";
import ManualSupplierForm from "./ManualSupplierForm";
import SupplierRequestWarning from "./SupplierRequestWarning";
import SupplierName from "./SupplierName";
import RentPriceColumn from "./PurchaseRentOnly/RentPriceColumn";
import OutboundCallButton from "../../../OutboundCallButton";
import ManualRentSupplierForm from "./ManualRentSupplierForm";
import LocationName from "./Locations/LocationName.vue";

export default {
    name: "SupplierSelection",
    props: ['showModal', 'requests'],
    components: {
        LocationName,
        ManualRentSupplierForm,
        RentPriceColumn,
        SupplierName,
        SupplierRequestWarning,
        ManualSupplierForm,
        AddressTeaser,
        ShortProductTeaser,
        OrderItemTeaser,
        Modal,
        draggable,
        OutboundCallButton
    },
    mounted() {
        window.addEventListener('load', () => {
            $('[data-toggle="data-tooltip"]').tooltip();
            $('[data-toggle="popover"]').popover();
        })
    },
    data: () => ({
        drag: false,
        loading: false,
        products: [],
        availableRequests: [],
        marginHtml: 'Selecteer een leverancier',
        linkLocationId: null,
        checkAllBox: false,
    }),
    computed: {
        selectedLinkLocation() {
            return this.availableRequests.find(r => r.locations.id === this.linkLocationId).location;
        },
        order() {
            return this.$store.state.order;
        },
        orderItem() {
            return this.$store.state.order.order_items[0];
        },
        attribute() {
            return this.$store.state.order.order_items[0].attributes[0];
        },
        linkSupplierRequest() {
            return this.availableRequests.find(r => r.location_id === this.linkLocationId);
        }
    },
    watch: {
        requests: function (value) {
            this.availableRequests = value;
        },
    },
    methods: {
        checkAll() {
            this.availableRequests.forEach((value, index) => {
                value.should_send = !this.checkAllBox;
            });
        },
        toggleLinkId(locationId) {
            if (this.linkLocationId === locationId) {
                this.linkLocationId = null;
            } else {
                this.linkLocationId = locationId;
            }
        },
        saveAndLink(locationId) {
            if (!this.$store.getters.isConfirmed) {
                this.close();
                window.triggerAlert('Bevestig eerst de order.', 'danger');
                return;
            }
            this.loading = true;
            this.$store.dispatch('updateSupplierRequests', {
                requests: this.availableRequests.sort(r => r.location_id === locationId),
            }).then(() => {
                let request = this.order.supplier_requests.find(r => r.location_id === this.linkLocationId);
                this.$store.dispatch('acceptSupplierRequest', request).then(() => {
                    this.loading = false;
                    this.$emit('close');
                });
            });
        },
        checkMove(e) {
            let index = e.draggedContext.index;
            return (this.availableRequests[index].id && this.availableRequests[index].status_id === 'not_sent') || !this.availableRequests[index].id;
        },
        close() {
            this.$emit('close');
        },
        save(andSend = false) {
            if (!this.$store.getters.isConfirmed && andSend) {
                this.close();
                window.triggerAlert('Bevestig eerst de order.', 'danger');
                return;
            }
            this.loading = true;
            return this.$store.dispatch('updateSupplierRequests', {
                requests: this.availableRequests,
                send: andSend,
            }).then(() => {
                this.loading = false;
                this.close();
                return true;
            });
        }
    }
}
</script>
