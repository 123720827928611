<template>
    <div style="display: flex; flex-direction: column">
        <div v-if="order.customer" style="display:flex; flex-direction: column; flex-grow: 1;">
            <div class="customerCard" style="padding-left: 20px !important; flex-grow: 1;">
                <div class="customerCard-tools dropdown">
                    <a id="customerDropdown" class="dropdown-toggle customerCard-tool" href="#" role="button"
                       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <div class="icon icon--ellipsis"></div>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="customerDropdown">
                        <a @click="editing = true" class="dropdown-item" href="#">
                            Bewerken
                        </a>
                    </div>
                </div>
                <div class="customerCard-header">
                    <h2 class="customerCard-header-title">

                        <a href="#" class="pointer" @click="editing = true" title="Order nummer" data-toggle="tooltip">
                            #{{ order.order_nr }}
                        </a>
                    </h2>

                    <div class="customerCard-header-details">
                        <div class="customerCard-header-detail" title="Aangemaakt op" data-toggle="tooltip">
                            <i class="fal fa-cart-plus"></i>&nbsp; {{ order.created_at }}
                        </div>
                        <div v-if="getOrderOrigin()" class="customerCard-header-detail" title="Aangemaakt via" data-toggle="tooltip">
                            <i class="fal fa-route"></i>&nbsp; {{ getOrderOrigin().name }}
                        </div>
                        <div class="customerCard-header-detail" title="Betaald op" data-toggle="tooltip">
                            <i class="fal fa-money-bill-wave"></i>&nbsp; {{ order.paid_at }}
                        </div>
                        <div class="customerCard-header-detail" v-if="order.invoice_address" title="Factuuradres" data-toggle="tooltip">
                            <i class="icon icon--marker"></i>&nbsp; {{ order.invoice_address.full_address }}
                        </div>
                    </div>
                </div>

                <div class="customerCard-content">
                    <div class="customerCard-column">
                        <div class="customerCard-content-item">
                            <i class="fal fa-euro-sign"></i>

                            <div @click="editItem" class="tag pointer tag--withImage" v-if="getPaymentMethod() && getPaymentMethod().image_url">
                                <div class="tag-image"
                                     :style="'background-image: url(' + getPaymentMethod().image_url + ')'">
                                </div>
                            </div>

                            <div @click="editItem" class="tag pointer" v-if="getPaymentMethod() && !getPaymentMethod().image_url">
                                {{ getPaymentMethod().name }}
                            </div>

                            <div @click="editing = true" class="alert alert--danger pointer" v-if="order.unpaid_invoices_count == 1">
                                <span v-if="order.unpaid_invoices_count == 1 && order.payment_method.creates_payment_request">
                                    Er staat nog {{ order.unpaid_invoices_count }} betaalverzoek open | {{ getOpenAmount() | format_money }} | +{{ order.unpaid_invoices[0].days_ago }} dgn
                                </span>
                                <span v-if="order.unpaid_invoices_count == 1 && !order.payment_method.creates_payment_request">
                                    Er staat nog {{ order.unpaid_invoices_count }} factuur open | {{ getOpenAmount() | format_money }} | +{{ order.unpaid_invoices[0].days_ago }} dgn
                                </span>
                            </div>
                            <div class="tag tag--success" v-if="order.invoices_count > 0 && order.unpaid_invoices_count == 0">
                                Betaald
                            </div>
                            <div @click="editing = true" class="alert alert--danger pointer" v-if="order.unpaid_invoices_count > 1">
                                <span v-text="order.unpaid_invoices_count"></span> openstaande facturen | {{ getOpenAmount() | format_money }} | +{{ order.unpaid_invoices[0].days_ago }} dgn
                            </div>

                        </div>
                        <div class="customerCard-content-item" v-for="financialEvent in order.financial_events">
                            <div :class="financialEvent.event_type.alert_class">
                                {{ financialEvent.event_type.name }}
                            </div>
                        </div>
                        <div v-if="order.unpaid_invoices_count">
                            <button @click="resendPaymentRequest" title="Betaalverzoek versturen" data-toggle="tooltip" class="btn btn-outline-secondary" type="button"><i class="fal fa-paper-plane"></i></button>
                        </div>
                        <div v-if="order.advice_data">
                            <button @click="showAdviceData" title="Adviesdata tonen" data-toggle="tooltip" class="btn btn-outline-secondary" type="button">
                                <i class="fal fa-lightbulb-on"></i>
                            </button>
                        </div>
                        <div v-if="order.latest_call">
                            <button @click="showCallData" title="Gekoppeld telefoongesprek tonen" data-toggle="tooltip" class="btn btn-outline-secondary" type="button">
                                <i class="fal fa-phone-plus"></i>
                            </button>
                        </div>
                        <div v-if="order.review">
                            <button :data-modal="'/reviews/' + order.review.id + '/edit'" title="Review tonen" data-toggle="tooltip" class="btn btn-outline-secondary" type="button">
                                <i class="fal fa-star"></i>
                                <span class="font-weight-normal">
                                    {{ order.review.total_score }}
                                </span>
                            </button>
                        </div>
                    </div>
                    <div class="customerCard-column">
                        <button @click="editItem" v-if="order.id" type="button" class="btn btn-light">
                            Order wijzigen <i class="far fa-pencil"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <order-modal @onClose="onOrderModalClose" :editing="editing"></order-modal>
        <advice-data-modal ref="adviceDataModal" @onClose="showAdviceDataModal = false" :show="showAdviceDataModal"></advice-data-modal>
        <call-data-modal ref="callDataModal" @onClose="showCallDataModal = false" :show="showCallDataModal"></call-data-modal>
    </div>
</template>

<script>

import FormControl from "./FormControl";
import Modal from "./Modal";
import CustomerForm from "./CustomerForm";
import OutboundCallButton from "../../OutboundCallButton";
import AdviceDataModal from "./AdviceDataModal";
import CallDataModal from "./CallDataModal";
import OrderModal from "./OrderModal.vue";

export default {
    name: "OrderDetails",
    components: {OrderModal, AdviceDataModal, CallDataModal, OutboundCallButton, CustomerForm, Modal, FormControl },

    data: () => ({
        editing: false,
        showAdviceDataModal: false,
        showCallDataModal: false,
    }),
    computed: {
        order() {
            return this.$store.state.order;
        }
    },
    methods: {
        onOrderModalClose(showProductForm = false) {
            this.editing = false;
            if (showProductForm) {
                this.editItem();
            }
        },
        resendPaymentRequest() {
            this.$store.dispatch('resendPaymentRequest')
        },
        getPaymentMethod() {
            return this.$store.state.paymentMethods.find(p => p.id === this.order.payment_method_id)
        },
        getOrderOrigin() {
            if (!this.order.order_origin_id) {
                return;
            }
            return this.$store.state.orderOrigins.find(p => p.id === this.order.order_origin_id)
        },
        getTotalAmount() {
            return this.order.unpaid_invoices.reduce((s, item) => {
                return s + item.open_amount
            }, 0)
        },
        getOpenAmount() {
            return this.order.unpaid_invoices.reduce((s, item) => {
                return s + item.open_amount
            }, 0)
        },
        loadCustomerModal() {
            if (this.order.customer) {
                window.QuickCustomerEdit.Load(`/order-customer/${this.order.customer.id}/edit`);
            } else {
                window.QuickCustomerEdit.Load(`/order-customer/create`);
            }
        },
        editItem() {
            if (this.$store.getters.isEditable) {
                this.$store.dispatch('showProductSelection', {
                    order_item: this.order.order_items[0],
                });
            }
        },
        showAdviceData() {
            this.showAdviceDataModal = true;
        },

        showCallData() {
            this.showCallDataModal = true;
        },
    }
}
</script>
